<template>
  <div class="reset-password">
    <password-chooser
      button-label="Reset Password"
      @choose="handlePwdChoice"
    >
      Please choose a new password, then click the "Reset Password" button.
    </password-chooser>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { resetPassword } from '@/adonis-api/auth';
import { userDefaultPath } from '@/router/router-helpers';
import PasswordChooser from '../../global/PasswordChooser.vue';

export default {
  components: { PasswordChooser },
  methods: {
    async handlePwdChoice(password) {
      try {
        const authInfo = await resetPassword({
          token: this.$route.params.pathMatch,
          password,
        });

        await this.$store.dispatch('auth/forceLogin', authInfo);
        this.$router.push(userDefaultPath(this.$router));
      } catch (e) {
        // blah
        const error = _get(e.response, 'data.error.message');

        this.$notify.error({
          message: error || 'An error occurred. This issue has been reported.',
        });
        if (!error) {
          this.$reportError(e);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 4em;
}

.instructions {
  margin-bottom: 1em;
  max-width: 380px;
}

.box {
  width: 340px;
  padding: 20px 20px 5px;
  background: #efefef;
  border-radius: 8px;
  border: 1px solid #ccc;

  .el-button {
    width: 100%;
  }

  .el-button--text {
    margin-left: 0;
    font-size: 0.85rem;
  }
}

.password-meter {
  margin-top: 1em;
}
</style>
